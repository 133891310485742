import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { makeGetDataAccount } from "../../../lib/selector";

import _ from "lodash";
import RenderLoginInput from "../../../components/input/renderInputLogin";
import RenderBankSearch from "../../../components/input/renderSearchBank";
import { handleApiErrors } from "../../../lib/api-errors";

import { ReactComponent as IconModalWn } from "../../../assets/img/icon/ic-warning.svg";
import { ReactComponent as IconModalWn1 } from "../../../assets/img/icon/ic-warning1.svg";
import { ReactComponent as IconUpDown } from "../../../assets/img/icon/ic_arrow.svg";

import RenderNormalSelect from "../../../components/input/renderNormalSearch";
import RenderRadio from "../../../components/input/renderRadio";

import { IoMdClose } from "react-icons/io";
import RenderInput from "../../../components/input/renderInputLogin";
import { setToast } from "../../../containers/client/actions";
import { removeAccent } from "../../../util";
import { lengthRequired, required } from "../../../util/validation";

import { setDataAccount } from "../../client/actions";

import { translate } from "react-i18next";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const length6 = lengthRequired(6);

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FormResService(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const {
    handleSubmit,
    submitting,
    handleNextSubStep,
    handlePreSubStep,

    formTP,
    formBankCode,
    formSTK,
    formDKSMS,
    formGDE,
    formDKTK,
    formGDTD,
    sid,
    formBranchName,
    dataAccount,
    orcData,
    t,
  } = props;
  const [selectBranch, setSelectBranch] = useState(false); // Chọn chi nhánh
  const [optsBankBranch, setOptsBankBranch] = useState([]); // Chi nhánh ngân hàng
  const [listBank, setListBank] = useState([]); // Danh sách ngân hàng
  const [listBranchBank, setBranchBank] = useState([]); //Danh sách chi nhánh ngân hàng

  const [nameBank, setNameBank] = useState(); //Lưu tên ngân hàng
  const [nameBranchBank, setNameBranchBank] = useState(); //Lưu tên chi nhánh
  const [nameCityBank, setNameCityBank] = useState(); //Lưu thành phố

  const [listCity, setListCity] = useState();
  const [optsProvince, setOptsProvince] = useState([]); // Tỉnh/ Thành phố

  const [checkDKTK, setCheckDKTK] = useState(true); //Check đăng tí tài khoản thụ hưởng
  const [checkGDTD, setCheckGDTD] = useState(true); //Check giao dịch qua tổng đài
  const [checkEorSMS, setCheckEorSMS] = useState(true); //Check sms hoặc email

  const preFormBankCode = usePrevious(formBankCode);
  const preFormBranchName = usePrevious(formBranchName);
  const preFormGDTD = usePrevious(formGDTD);
  const preFormDKTK = usePrevious(formDKTK);
  const preFormTP = usePrevious(formTP);
  const preListBranchBank = usePrevious(listBranchBank);
  const preListCity = usePrevious(listCity);

  //#region useEffect
  useEffect(() => {
    getListBank();
    getCity();
    initForm();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (listCity && listBank && listBank.length > 0 && listCity.length > 0) {
      dispatch(
        change(
          "formResStep21",
          "formBankCode",
          dataAccount && dataAccount?.C_BANK_CODE
        )
      );
      dispatch(
        change(
          "formResStep21",
          "formTP",
          dataAccount && dataAccount?.C_BANK_PROVINCE
        )
      );
    }
  }, [listBank, listCity]);

  useEffect(() => {
    if (optsBankBranch && optsBankBranch.length > 0) {
      dispatch(
        change(
          "formResStep21",
          "formBranchName",
          dataAccount && dataAccount?.C_BRANCH_CODE
        )
      );
    }
  }, [optsBankBranch]);

  useEffect(() => {
    if (formTP || formBranchName) {
      setSelectBranch(true);
    }
  }, [formTP, formBranchName]);

  useEffect(() => {
    if (listCity && !_.isEqual(listCity, preListCity)) {
      let _optsProvince = [];
      // _optsProvince.push({ value: '', label: '-- Chọn tỉnh thành --' });
      listCity.forEach((element) => {
        _optsProvince.push({ value: element.C_CODE, label: element.C_NAME });
      });
      setOptsProvince(_optsProvince);
    }
  }, [listCity]);

  useEffect(() => {
    if (listCity && formTP && !_.isEqual(formTP, preFormTP)) {
      const tmp = _.find(listCity, (o) => o.C_CODE === formTP)?.C_NAME;
      setNameCityBank(tmp);
      getListBranchBank();
    }
  }, [formTP, listBranchBank]);

  useEffect(() => {
    if (
      listBranchBank &&
      formBranchName &&
      !_.isEqual(formBranchName, preFormBranchName)
    ) {
      const tmp = _.find(
        listBranchBank,
        (o) => o.C_SUB_BRANCH_CODE === formBranchName
      )?.C_SUB_BRANCH_NAME;
      setNameBranchBank(tmp);
    }
  }, [formBranchName, listBranchBank]);

  useEffect(() => {
    if (listBank && formBankCode && !_.isEqual(formBankCode, preFormBankCode)) {
      const tmp = _.find(
        listBank,
        (o) => o.C_BANK_CODE === formBankCode
      )?.C_BANK_NAME;
      setNameBank(tmp);

      getListBranchBank();
    }
  }, [formBankCode, listBank]);

  useEffect(() => {
    if (formDKTK && !_.isEqual(formDKTK, preFormDKTK)) {
      setCheckDKTK(formDKTK === "0");
    }
  }, [formDKTK]);

  useEffect(() => {
    setCheckEorSMS(formDKSMS === "0" && formGDE === "0");
  }, [formDKSMS, formGDE]);

  useEffect(() => {
    if (formGDTD && !_.isEqual(formGDTD, preFormGDTD)) {
      setCheckGDTD(formGDTD === "0");
      // dispatch(change("formResStep21", "formPassword", ""));
    }
  }, [formGDTD]);

  useEffect(() => {
    if (
      !_.isEqual(formBankCode, preFormBankCode) &&
      formBankCode !== dataAccount?.C_BANK_CODE
    ) {
      dispatch(change("formResStep21", "formSTK", ""));
      dispatch(change("formResStep21", "formTP", ""));
      dispatch(change("formResStep21", "formBranchName", ""));
    }
  }, [formBankCode, dataAccount]);

  console.log("formBankCode", formBankCode);

  useEffect(() => {
    if (listBranchBank && !_.isEqual(listBranchBank, preListBranchBank)) {
      let _optsBank = [];

      listBranchBank.forEach((element) => {
        _optsBank.push({
          value: element.C_SUB_BRANCH_CODE,
          label: element.C_SUB_BRANCH_NAME,
        });
      });

      setOptsBankBranch(_optsBank);
    }
  }, [listBranchBank]);

  const initForm = () => {
    dispatch(
      change(
        "formResStep21",
        "formUTB",
        (dataAccount && dataAccount?.C_UTB) || "1"
      )
    );
    dispatch(
      change(
        "formResStep21",
        "formDKTK",
        (dataAccount && dataAccount?.C_DKTK) || "1"
      )
    );
    dispatch(
      change(
        "formResStep21",
        "formDKSMS",
        (dataAccount && dataAccount?.C_DKSMS) || "1"
      )
    );
    dispatch(
      change(
        "formResStep21",
        "formGDE",
        (dataAccount && dataAccount?.C_GDE) || "1"
      )
    );
    dispatch(
      change(
        "formResStep21",
        "formGDTD",
        (dataAccount && dataAccount?.C_GDTD) || "1"
      )
    );
    dispatch(
      change(
        "formResStep21",
        "formGDTT",
        (dataAccount && dataAccount?.C_GDTT) || "1"
      )
    );
    dispatch(
      change(
        "formResStep21",
        "formSTK",
        dataAccount && dataAccount?.C_BANK_ACCOUNT
      )
    );
    dispatch(
      change(
        "formResStep21",
        "formPassword",
        dataAccount && dataAccount?.C_MOBILE_TRADING_PASSWORD
      )
    );
  };

  const getListBank = () => {
    const params = {
      user: "back",
      cmd: "LIST_BANK",
      param: {},
      sid: sid,
    };

    const request = fetch(`${apiUrl}/listBank`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiBank(request);
  };

  const handleApiBank = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          setListBank(json.data);
        } else {
          // _handleToast(json.sRs, 'error');
        }
      })
      .catch((error) => {
        // throw error;
      });
  };

  const getCity = () => {
    const params = {
      user: "back",
      cmd: "LIST_PROVINCE",
      param: {},
      sid: dataAccount?.S_ID,
    };

    const request = fetch(`${apiUrl}/listProvince`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiGetCity(request);
  };

  const handleApiGetCity = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          setListCity(json.data);
        } else {
          console.error(json.sRs);
        }
      })
      .catch((error) => {
        // throw error;
      });
  };

  const getListBranchBank = () => {
    const params = {
      user: "back",
      cmd: "LIST_BRANCH_BANK",
      param: {
        BANK_CODE: formBankCode,
        PROVINCE_CODE: formTP,
      },
      sid: sid,
    };

    const request = fetch(`${apiUrl}/listBank`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiBranchBank(request);
  };

  const handleApiBranchBank = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          setBranchBank(json.data);
        } else {
          // _handleToast(json.sRs, 'error');
        }
      })
      .catch((error) => {
        // throw error;
      });
  };

  const _handClearDKTK = () => {
    dispatch(change("formResStep21", "formBankCode", ""));
    dispatch(change("formResStep21", "formSTK", ""));
    dispatch(change("formResStep21", "formTP", ""));
    dispatch(change("formResStep21", "formBranchName", ""));
  };

  function submit(values) {
    if (
      checkEorSMS ||
      (dataAccount &&
        dataAccount?.C_FULL_NAME?.toUpperCase() !==
          orcData?.data.fullName.toUpperCase())
    )
      return;

    let _dataAcc = Object.assign({}, dataAccount);
    _dataAcc.C_BANK_CODE = values?.formDKTK === "1" ? values.formBankCode : "";
    _dataAcc.C_BANK_NAME = values?.formDKTK === "1" ? nameBank : "";
    _dataAcc.C_BRANCH_CODE =
      values?.formDKTK === "1" ? values.formBranchName : "";
    _dataAcc.C_BRANCH_NAME = values?.formDKTK === "1" ? nameBranchBank : "";
    _dataAcc.C_BANK_PROVINCE = values?.formDKTK === "1" ? values.formTP : "";
    _dataAcc.C_BANK_PROVINCE_NAME =
      values?.formDKTK === "1" ? nameCityBank : "";
    _dataAcc.C_BANK_ACCOUNT = values?.formDKTK === "1" ? values.formSTK : "";

    _dataAcc.C_UTB = values.formUTB || "";
    _dataAcc.C_DKTK = values.formDKTK || "";
    _dataAcc.C_DKSMS = values.formDKSMS || "";
    _dataAcc.C_GDTT = values.formGDTT || "";
    _dataAcc.C_GDE = values.formGDE || "";
    _dataAcc.C_GDTD = values.formGDTD || "";
    _dataAcc.C_MOBILE_TRADING_PASSWORD =
      values.formGDTD === "1" ? values.formPassword : "";

    dispatch(setDataAccount(_dataAcc));

    handleNextSubStep();
    history.push("/open-account/sign-contract");
  }

  const handlePreStep = () => {
    history.push("/open-account/check-info");
    handlePreSubStep();
  };

  const _handleToast = (msg, type = "info") => {
    const toastMsg = {
      id: Math.random(),
      type,
      title: t("notification"),
      msg,
    };
    dispatch(setToast(toastMsg));
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      autoComplete="off"
      className=" flex flex-column gap-r-4 form-group"
    >
      <div className="bg-skin-sur-primary rounded-2xl flex flex-row justify-content-between p-r-16">
        <span className="text-sm font-medium text-skin-title">
          {t("advance-cash-auto")}
        </span>
        <div className="flex flex-row gap-8">
          <Field
            name="formUTB"
            component={RenderRadio}
            type="radio"
            value="1"
            id="formUTB"
            labelName={t("yes")}
          />
          <Field
            name="formUTB"
            component={RenderRadio}
            type="radio"
            value="0"
            id="formUTB"
            labelName={t("no")}
            disabled
          />
        </div>
      </div>
      <div className="bg-skin-sur-primary rounded-2xl flex flex-column gap-r-4 p-r-16">
        <div className="d-flex flex-row justify-content-between">
          <span className="text-sm font-bold text-skin-title">
            {t("register-beneficiary-account")}
          </span>
          <div className="flex flex-row gap-8">
            <Field
              name="formDKTK"
              component={RenderRadio}
              type="radio"
              value="1"
              id="formDKTK"
              labelName={t("yes")}
            />
            <Field
              name="formDKTK"
              component={RenderRadio}
              type="radio"
              value="0"
              id="formDKTK"
              labelName={t("no")}
            />
          </div>
        </div>

        {!checkDKTK && (
          <div className="flex flex-column gap-4">
            {formBankCode && formSTK && (
              <div
                className="rounded-3xl bg-skin-sur-secondary flex flex-row gap-2 items-center"
                style={{
                  maxWidth: "100%",
                  width: "max-content",
                  padding: "8px 12px",
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL +
                      `/images/bank/${formBankCode}.png`
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "24px",
                    height: "24px",
                    minWidth: "24px",
                  }}
                ></div>
                <span className="text-sm font-normal text-skin-title line-break ">
                  {dataAccount &&
                    dataAccount?.C_FULL_NAME &&
                    removeAccent(dataAccount?.C_FULL_NAME.toUpperCase())}
                  {" - "} {formSTK}
                </span>
                <IoMdClose
                  className="text-skin-iwhite rounded-full border border-skin-white icon-circle-close"
                  onClick={() => _handClearDKTK()}
                />
              </div>
            )}
            <div className="rounded-2xl flex flex-column bg-skin-sur-tertiary gap-3 padding-3">
              <span className="style-accben text-sm font-medium text-skin-body rounded-xl bg-skin-sur-quatinary border border-skin-weak">
                {t("beneficiary-name")}
                {": "}
                <span className="text-sm font-semibold text-skin-title">
                  {dataAccount &&
                    dataAccount?.C_FULL_NAME &&
                    removeAccent(dataAccount?.C_FULL_NAME.toUpperCase())}
                </span>
              </span>
              <div className="grid grid-cl-2 items-baseline justify-center gap-r-4">
                <div className="w-100">
                  <label className="text-xs text-title">
                    {t("beneficiary-bank")}
                    <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="formBankCode"
                    type="text"
                    placeholderText={t("select-bank")}
                    component={RenderBankSearch}
                    className="py-3 px-2 mt-2 w-100 bg-skin-sur-secondary h-8"
                    validate={required}
                    opts={listBank}
                  />
                </div>
                <div className="w-100">
                  <label className="text-xs text-title">
                    {t("beneficiary-account-number")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="formSTK"
                    type="text"
                    placeholder={t("input-acc-num-ben")}
                    component={RenderInput}
                    className="py-3 px-4 mt-2 w-100 input-number-cus"
                    validate={required}
                    onKeyDown={(e) => {
                      const isNotNumber = !/\d/.test(e.key);
                      const isNavigationKey = [
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        "Delete",
                        "Tab",
                      ].includes(e.key);

                      if (isNotNumber && !isNavigationKey) {
                        e.preventDefault();
                      }
                    }}
                    maxLength="25"
                  />
                </div>
              </div>
              <span
                className="text-xs font-medium text-skin-subdued flex flex-row align-items-center gap-2 cursor-pointer"
                onClick={() => setSelectBranch(!selectBranch)}
              >
                {t("branch-city-optional")}
                <IconUpDown
                  className={`icon-up-down ${selectBranch ? "rotate-180" : ""}`}
                />
              </span>
              {selectBranch && (
                <div className="grid grid-cl-2 items-center justify-center gap-r-4">
                  <div className="w-100">
                    <Field
                      name="formTP"
                      type="text"
                      placeholderText={t("select-city")}
                      component={RenderNormalSelect}
                      className="py-3 px-4 w-100 text-base h-12 "
                      opts={optsProvince}
                    />
                  </div>
                  <div className="w-100">
                    <Field
                      name="formBranchName"
                      type="text"
                      placeholderText={t("select-branch")}
                      component={RenderNormalSelect}
                      className="py-3 px-4 w-100 text-base h-12 "
                      opts={optsBankBranch}
                    />
                  </div>
                </div>
              )}
            </div>
            {dataAccount &&
              dataAccount?.C_FULL_NAME?.toUpperCase() !==
                orcData?.data.fullName.toUpperCase() && (
                <div className="style-warning rounded-3xl flex flex-row gap-2">
                  <IconModalWn1 className="style-ic-wr" />
                  <span className="text-sm font-normal text-skin-red1">
                    {t("name-mismatch")}
                  </span>
                </div>
              )}
          </div>
        )}
      </div>
      <div className="p-r-16 rounded-2xl d-flex flex-row justify-content-between bg-skin-sur-primary">
        <span className="text-sm font-semibold text-skin-title">
          {t("register-sms")}
        </span>
        <div className="flex flex-row gap-8">
          <Field
            name="formDKSMS"
            component={RenderRadio}
            type="radio"
            value="1"
            id="formDKSMSY"
            labelName={t("yes")}
          />
          <Field
            name="formDKSMS"
            component={RenderRadio}
            type="radio"
            value="0"
            id="formDKSMSN"
            labelName={t("no")}
          />
        </div>
      </div>
      <div className="rounded-2xl d-flex flex-row justify-content-between bg-skin-sur-primary p-r-16">
        <span className="text-sm font-semibold text-skin-title">
          {t("online-transactions")}
        </span>
        <div className="flex flex-row gap-8">
          <Field
            name="formGDTT"
            component={RenderRadio}
            type="radio"
            value="1"
            id="formGDTTY"
            labelName={t("yes")}
          />
          <Field
            name="formGDTT"
            component={RenderRadio}
            type="radio"
            value="0"
            id="formGDTTN"
            labelName={t("no")}
            disabled
          />
        </div>
      </div>
      <div className="rounded-2xl d-flex flex-column gap-3 bg-skin-sur-primary p-r-16">
        <div className="d-flex flex-row justify-content-between">
          <span className="text-sm font-semibold text-skin-title">
            {t("telephone-transactions")}
          </span>
          <div className="flex flex-row gap-8">
            <Field
              name="formGDTD"
              component={RenderRadio}
              type="radio"
              value="1"
              id="formGDTDY"
              labelName={t("yes")}
            />
            <Field
              name="formGDTD"
              component={RenderRadio}
              type="radio"
              value="0"
              id="formGDTDN"
              labelName={t("no")}
            />
          </div>
        </div>

        {!checkGDTD && (
          <div>
            <div
              className="text-xs font-medium"
              style={{
                marginBottom: "8px",
              }}
            >
              {t("transaction-password")}
              <span className="text-danger">*</span>
            </div>
            <Field
              name="formPassword"
              type="password"
              autoComplete="off"
              placeholder={t("input-password")}
              component={RenderLoginInput}
              validate={[required, length6]}
              maxLength="6"
              onKeyDown={(e) => {
                const isNotNumber = !/\d/.test(e.key);
                const isNavigationKey = [
                  "Backspace",
                  "ArrowLeft",
                  "ArrowRight",
                  "Delete",
                  "Tab",
                ].includes(e.key);

                if (isNotNumber && !isNavigationKey) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        )}
      </div>
      <div className="rounded-2xl d-flex flex-row justify-content-between bg-skin-sur-primary p-r-16">
        <span className="text-sm font-semibold text-skin-title">
          {t("email-information-service")}
        </span>
        <div className="flex flex-row gap-8">
          <Field
            name="formGDE"
            component={RenderRadio}
            type="radio"
            value="1"
            id="formGDEY"
            labelName={t("yes")}
          />
          <Field
            name="formGDE"
            component={RenderRadio}
            type="radio"
            value="0"
            id="formGDEN"
            labelName={t("no")}
          />
        </div>
      </div>
      {checkEorSMS && (
        <div className="style-wr rounded-3xl flex flex-row gap-2">
          <IconModalWn
            className="ic-warn"
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          <span className="text-sm font-normal text-skin-warning">
            {t("email-or-sms")}
          </span>
        </div>
      )}
      <div
        className="flex flex-row-reverse justify-content-start gap-2 w-100"
        style={{
          paddingTop: "24px",
        }}
      >
        <button
          type="submit"
          disabled={submitting}
          className="success btn-submit "
        >
          {t("continue-step")}
        </button>{" "}
        <button className="btn-close  " onClick={() => handlePreStep()}>
          {t("pre")}
        </button>
      </div>
    </form>
  );
}

FormResService = reduxForm({
  form: "formResStep21",
  enableReinitialize: true,
})(FormResService);

const selector = formValueSelector("formResStep21");

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  const {
    formBankCode,
    formBranchName,
    formSTK,
    formTP,
    formUTB,
    formDKTK,
    formDKSMS,
    formGDTT,
    formGDE,
    formGDTD,
    formPassword,
  } = selector(
    state,
    "formBankCode",
    "formBranchName",
    "formSTK",
    "formTP",
    "formUTB",
    "formDKTK",
    "formDKSMS",
    "formGDTT",
    "formGDE",
    "formGDTD",
    "formPassword"
  );

  return {
    dataAccount: getDataAccount(state),

    formBankCode,
    formBranchName,
    formSTK,
    formTP,
    formUTB,
    formDKTK,
    formDKSMS,
    formGDTT,
    formGDE,
    formGDTD,
    formPassword,
  };
};

export default connect(mapStateToProps)(
  translate("translations")(FormResService)
);
