import { replaceAll } from ".";

export const required = (value) =>
  value ? undefined : "Thông tin này bắt buộc";

export const lengthRequired = (length) => (value) => {
  // console.log(value, value.length)
  if (!value) return undefined;

  const _value = replaceAll(value, " ", "");
  const __value = replaceAll(_value, "_", "");
  // console.log(__value, __value.length)
  if (__value && __value.length === length) return undefined;

  return `Độ dài ${length} ký tự`;
};

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Nhập tối đa ${max} ký tự` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `Nhập tối thiểu ${min} ký tự` : undefined;

export const number = (value) =>
  value && isNaN(Number(value)) ? "Hãy nhập số" : undefined;

export const validatePhone = (value) => {
  let vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  const _value = value.replace(/ /g, "");
  if (!_value || (vnf_regex.test(_value) && _value.indexOf("_") < 0))
    return undefined;

  return "Số điện thoại không đúng định dạng";
};

export const validateBirth = (value) => {
  let vnf_regex =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  // console.log(vnf_regex.test(value))
  if (!value || (vnf_regex.test(value) && value.indexOf("_") < 0))
    return undefined;

  return "Ngày tháng không đúng định dạng";
};

export const validateEmail = (value) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!value || re.test(value)) return undefined;

  return "Email không đúng định dạng";
};
