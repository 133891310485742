/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";

import PerfectScrollBar from "react-perfect-scrollbar";
import { FaCheck } from "react-icons/fa6";
import { ReactComponent as IconUpDown } from "../../assets/img/icon/ic_arrow.svg";

import _ from "lodash";

function usePrevious(value) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const RenderNormalSelect = (props) => {
  const showRef = useRef(null);

  const [txtSearch, setTxtSearch] = React.useState();
  const [showSelect, setShowSelect] = React.useState(false);
  const [indexActive, setIndexActive] = React.useState(-1);

  const {
    placeholderText,
    input,
    disabled,
    opts,
    className,
    required = false,
    meta: { touched, error },
  } = props;

  const preValue = usePrevious(input?.value);
  const preIndexActive = usePrevious(indexActive);

  React.useEffect(() => {
    if (indexActive > -1 && !_.isEqual(indexActive, preIndexActive)) {
      let _rootRow = document.getElementById("active-select");
      if (_rootRow) {
        _rootRow.scrollIntoViewIfNeeded(false);
      }
    }
  }, [indexActive]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showRef &&
        showRef.current &&
        !showRef.current?.contains(event.target)
      ) {
        setShowSelect(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);
    // See note below
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if (!_.isEqual(input?.value, preValue)) {
      if (input?.value) {
        const _optSelected = _.find(opts, (o) => o.value === input?.value);
        if (_optSelected) {
          setTxtSearch(_optSelected.labelCode || _optSelected.label);
        }
      } else setTxtSearch(placeholderText || "Chọn");
    }
  }, [input?.value]);

  function handleSelect(val) {
    setShowSelect(false);
    input?.onChange(val);
  }

  function _handleShow() {
    if (disabled) return;
    // inputRef && inputRef.current.focus();
    setShowSelect(!showSelect);
  }

  function onKeyDown(e) {
    if (!opts || !opts.length) {
      setShowSelect(false);
      return;
    }

    if (e.key === "ArrowDown" || e.keyCode === 40) {
      e.preventDefault();
      if (indexActive < opts.length - 1) {
        setIndexActive(indexActive + 1);
      } else setIndexActive(-1);
    }

    if (e.key === "ArrowUp" || e.keyCode === 38) {
      e.preventDefault();
      if (indexActive > 0) setIndexActive(indexActive - 1);
      if (indexActive === 0) setIndexActive(-1);
      if (indexActive < 0) setIndexActive(opts.length - 1);
    }

    if (e.key === "Enter") {
      e.preventDefault();
      if (indexActive < 0 || indexActive > opts.length) return;
      // setSelected(true)
      const _record = opts[indexActive];
      // setTxtSearch(_record.label);
      input.onChange(_record.value);
      setShowSelect(false);
    }
  }

  return (
    <div>
      <div
        {...input}
        className={
          `rounded-3xl relative disabled:bg-skin-natural-2 bg-skin-input ` +
          `${
            touched && error ? "border border-skin-sell" : "border-skin-input"
          } ` +
          (showSelect ? "border border-skin-white " : " ") +
          (className || "")
        }
        disabled={disabled}
        ref={showRef}
        onKeyDown={onKeyDown}
        tabIndex="0"
        style={{
          height: "36px",
        }}
      >
        <div
          className={
            "w-100 h-100 cursor-pointer flex items-center font-normal text-sm " +
            (!input?.value ? "text-skin-subdued " : "text-skin-base ")
          }
          onClick={() => _handleShow()}
          style={{
            paddingRight: "28px",
          }}
        >
          {txtSearch || placeholderText || ""}
        </div>
        <ul
          className={
            "scroll-cus absolute border border-skin-weak bg-skin-primary rounded-2xl select-cus " +
            (showSelect ? "" : "hidden")
          }
          style={{
            width: "max-content",
            zIndex: "10",
            maxHeight: "225px",
            minWidth: "100%",
            left: "0px",
            top: "calc(100% + 2px)",
            padding: "16px",
            marginTop: "4px",
          }}
        >
          <PerfectScrollBar
            style={{
              maxHeight: "205px",
            }}
          >
            {!required && (
              <li
                className=" font-medium text-skin-subdued"
                onClick={() => handleSelect("")}
              >
                {placeholderText}
              </li>
            )}
            {opts &&
              !!opts.length &&
              opts.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleSelect(item.value)}
                  className={
                    "flex items-center justify-between rounded-3xl cursor-pointer bank-li " +
                    (input?.value === item.value ? "selected " : " ") +
                    (indexActive === index
                      ? "bg-skin-navActive bank-span-active "
                      : "bg-skin-inversewhite ")
                  }
                  style={{
                    padding: "0px 12px",
                    margin: "8px 0px",
                    height: "36px",
                  }}
                  id={indexActive === index ? "active-select" : ""}
                >
                  {item.label}

                  {input?.value === item.value && (
                    <FaCheck
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "8px",
                        margonBottom: "4px",
                      }}
                    />
                  )}
                </li>
              ))}
          </PerfectScrollBar>
        </ul>
        <a
          className="absolute -translate-y-1/2 cursor-pointer"
          style={{
            right: "16px",
            top: "25%",
          }}
        >
          <IconUpDown
            className={`text-skin-title ${showSelect ? "rotate-180" : ""}`}
            style={{
              width: "20px",
              height: "20px",
              padding: "1px",
            }}
            onClick={() => _handleShow()}
          />
        </a>
      </div>
      {touched && error && (
        <small className="text-left text-sm text-skin-sell">{error}</small>
      )}
    </div>
  );
};

export default RenderNormalSelect;
