import {
  CHECK_ACC_REQUESTING,
  CHECK_ACC_REQUEST_ERROR,
  CHECK_ACC_REQUEST_SUCCESS,
  PUSH_LOG_REQUESTING,
  PUSH_LOG_REQUEST_ERROR,
  PUSH_LOG_REQUEST_SUCCESS,
  LIST_ACC_REQUESTING,
  LIST_ACC_REQUEST_ERROR,
  LIST_ACC_REQUEST_SUCCESS,
  DATA_ACCOUNT_SET,
  SAVE_LIST_ACC_HOLD,
  SET_STEP_RES,
  SAVE_TIME_SS,
} from "./constants";

import i18n from "../../i18n";

const initialSate = {
  toast: null,
  dataAccount: null,
  listAccHold: [],
  setStepRes: null,
  setTimeSs: null,
  pushLog: null,
  listAcc: null,

  checkAccount: null,
  checkAccountRequesting: false,
  checkAccountSuccessful: false,
  checkAccountErrors: [],

  setting: {},
};

const reducer = function clientReducer(state = initialSate, action) {
  switch (action.type) {
    case "API_ERR":
    case "REQUEST_PRIVATE_FALSE":
      return {
        ...state,
        toast: {
          msg: action.resData?.msg || action.msg,
          title: i18n.t("notification"),
          type: "error",
        },
      };
    case "SHOW_TOAST":
      return {
        ...state,
        toast: action.resData,
      };

    case "CLEAR_TOAST":
      return {
        ...state,
        toast: null,
      };

    case "SETTING_SET":
      return {
        ...state,
        setting: action.data,
      };

    case DATA_ACCOUNT_SET:
      return {
        ...state,
        dataAccount: action.resData,
      };

    case SAVE_LIST_ACC_HOLD:
      return {
        ...state,
        listAccHold: action.resData,
      };

    case SET_STEP_RES:
      return {
        ...state,
        setStepRes: action.resData,
      };

    case SAVE_TIME_SS:
      return {
        ...state,
        setTimeSs: action.resData,
      };

    case CHECK_ACC_REQUESTING:
      return {
        ...state,
        checkAccount: null,
        checkAccountRequesting: true,
        checkAccountSuccessful: false,
        checkAccountErrors: [],
      };

    case CHECK_ACC_REQUEST_SUCCESS:
      return {
        ...state,
        checkAccount: action.resData,
        checkAccountRequesting: false,
        checkAccountSuccessful: true,
      };

    case CHECK_ACC_REQUEST_ERROR:
      return {
        ...state,
        checkAccountRequesting: false,
        checkAccountSuccessful: false,
        checkAccountErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case PUSH_LOG_REQUESTING:
    case PUSH_LOG_REQUEST_ERROR:
      return {
        ...state,
        pushLog: null,
      };
    case PUSH_LOG_REQUEST_SUCCESS:
      return {
        ...state,
        pushLog: action.resData,
      };
    case LIST_ACC_REQUESTING:
    case LIST_ACC_REQUEST_ERROR:
      return {
        ...state,
        listAcc: [],
      };
    case LIST_ACC_REQUEST_SUCCESS:
      return {
        ...state,
        listAcc: action.resData,
      };

    default:
      return state;
  }
};

export default reducer;
