import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";

import {
  HashRouter as Router,
  // BrowserRouter as Router,
  Switch,
  Route,
  // useParams,
  Redirect,
} from "react-router-dom";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "aos/dist/aos.css";
import "./index.css";

import App from "./App";
import DefaultLayout from "./containers/defaultLayout";

// Import the index reducer and sagas
import IndexReducer from "./reducers";
import IndexSagas from "./sagas";

import Loading from "./components/loading";
import { ToastContainer } from "react-toastify";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware();

/*eslint-disable */
const composeSetup =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
/*eslint-enable */

/* prod */
const store = createStore(
  IndexReducer,
  composeSetup(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(IndexSagas);

/* disable console log when production build */
let consoleHolder = console;
function debug(bool) {
  if (!bool) {
    consoleHolder = console;
    console = {};
    Object.keys(consoleHolder).forEach(function (key) {
      console[key] = function () {};
    });
  } else {
    console = consoleHolder;
  }
}

debug(process.env.NODE_ENV !== "production");

/* end disable console log */

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router basename={process.env.PUBLIC_URL}>
        <React.Suspense fallback={<Loading />}>
          {/* <GlobalStyles /> */}
          <Route path="/" component={App}>
            <Switch>
              <Route
                path="/open-account"
                component={() => <DefaultLayout store={store} />}
              />
              <Redirect from="/" to="/open-account" />
            </Switch>
          </Route>
          {/* <ToastContainer
          autoClose={2000}
          pauseOnHover={false}
          pauseOnFocusLoss
        /> */}
        </React.Suspense>
      </Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);
