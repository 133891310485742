import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { translate } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { makeGetDataAccount } from "../../lib/selector";
import FormResSer from "./step2/formResService";

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function PageResService(props) {
  const {
    orcData,
    subStep,
    handlePreSubStep,
    handleNextSubStep,
    dataAccount,
    t,
    prePageFirst,
  } = props;

  const history = useHistory();
  const scrollRef = useRef(null);
  const headerRef = useRef();
  const [scrollHeight, setScrollHeight] = useState("100vh");

  const preSubStep = usePrevious(subStep);

  useEffect(() => {
    if (!dataAccount || subStep < 6) {
      history.push("/open-account/check-info");
      prePageFirst();
      return;
    }
  }, []);

  useEffect(() => {
    const calculateScrollHeight = () => {
      const headerHeight =
        headerRef.current?.getBoundingClientRect().height || 0;
      const extraHeight = window.innerWidth < 768 ? 92 : 0;
      setScrollHeight(`calc(100vh - ${headerHeight + 120 + extraHeight}px)`);
    };

    // Tính toán chiều cao ban đầu và khi resize
    calculateScrollHeight();
    window.addEventListener("resize", calculateScrollHeight);

    // Cuộn về đầu khi thay đổi subStep
    if (subStep && !_.isEqual(subStep, preSubStep) && scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }

    return () => {
      window.removeEventListener("resize", calculateScrollHeight);
    };
  }, [subStep]);

  return (
    <>
      <div
        className="md:col-span-2 grid gap-4 auto-rows-max  box-top_margin"
        ref={headerRef}
      >
        <div className=" w-100 grid grid-cols-5 gap-1">
          <div className="col-span-5 style-bg-header">
            <span className="text-base font-semibold text-skin-title ml-2">
              {t("register-service")}
            </span>
          </div>
        </div>
      </div>
      <PerfectScrollbar
        style={{ height: scrollHeight }}
        containerRef={(el) => {
          scrollRef.current = el;
        }}
      >
        {subStep === 6 && (
          <FormResSer
            handleNextSubStep={handleNextSubStep}
            handlePreSubStep={handlePreSubStep}
            prePageFirst={prePageFirst}
            orcData={orcData}
          />
        )}
        {/* {subStep === 7 && (
          <FormQuestion
            handlePreSubStep={handlePreSubStep}
            handleNextSubStep={handleNextSubStep}
          />
        )} */}
      </PerfectScrollbar>
    </>
  );
}

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  return {
    dataAccount: getDataAccount(state),
  };
};

export default connect(mapStateToProps)(
  translate("translations")(PageResService)
);
