import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import en from "./locales/en/index";
import vi from "./locales/vi/index";
import kr from "./locales/kr";

const resources = {
  en: {
    translations: en,
  },
  vi: {
    translations: vi,
  },
  kr: {
    translations: kr,
  },
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "vi",
    fallbackLng: "vi", // use en if detected lng is not available
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ",",
    },

    react: {
      wait: true,
    },
  });

export default i18n;
