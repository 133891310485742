import ReactModal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { ReactComponent as IconInfAuth } from "../../../assets/img/icon/ic-infAuth.svg";
import { memo, useState, useEffect, useRef } from "react";

ReactModal.setAppElement("#root");

const styleLg = {
  content: {
    top: "50%",
    transform: "translateY(-50%)",
    bottom: "auto",
    left: "calc( 50% - 307px )",
    height: "auto",
    width: "614px",
    padding: "0",
    borderWidth: "0",
    overflow: "inherit",
    borderRadius: "24px",
    background: "transparent",
  },
};

const styleXs = {
  content: {
    top: "50%",
    transform: "translateY(-50%)",
    bottom: "auto",
    left: "calc( 50% - 179px )",
    height: "auto",
    width: "358px",
    padding: "0",
    borderWidth: "0",
    overflow: "inherit",
    borderRadius: "24px",
    background: "transparent",
  },
};

function RegisterAuth(props) {
  const { handleCloseModal, progress, titleText, detailText } = props;
  const [dimensions, setDimensions] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ReactModal
      isOpen={true}
      contentLabel="onRequestClose Example"
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      style={dimensions >= 768 ? styleLg : styleXs}
      className="relative"
    >
      <div className="flex flex-column w-100 h-100 bg-skin-sur-primary border border-skin-weak rounded-3xl">
        <div
          className="text-skin-title text-left text-2xl font-semibold flex items-center justify-content-between gap-2"
          style={{
            padding: "24px",
          }}
        >
          {titleText || "Xác thực"}
          <IoMdClose
            className="rounded-3xl cursor-pointer text-skin-gray"
            style={{
              height: "24px",
              width: "24px",
            }}
            onClick={() => handleCloseModal()}
          />
        </div>
        <div
          className="flex flex-column gap-4 justify-center items-center pt-4 px-6 pb-6"
          style={{
            padding: "16px 24px 24px",
          }}
        >
          <div className="flex flex-column justify-center items-center gap-6">
            <IconInfAuth />
            <span className="text-lg text-center font-semibold text-skin-title">
              {detailText}
            </span>
            <div className="w-100 bg-skin-natural-10">
              <div
                className="bg-skin-natural-11"
                style={{
                  width: `${progress}%`,
                  transition: "width 0.5s",
                  height: "5px",
                  borderRadius: "20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default RegisterAuth;
