import React, { useEffect, useRef, useState } from "react";

import _ from "lodash";
import { translate } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeGetDataAccount } from "../../lib/selector";
import {
  getListAccount,
  getListAccSuccess,
  setDataAccount,
} from "../client/actions";
import FormAddInfor from "./step1/formAddInfor";
import FormAuthDocs from "./step1/formAuthDocs";
import FormAuthFace from "./step1/formAuthFace";
import FormCheckAccount from "./step1/formCheckAccount";
import FormSelectAccount from "./step1/formSelectAcc";

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function PageBaseInfor(props) {
  const {
    handleGetOrcData,
    orcData,
    subStep,
    handlePreSubStep,
    handleNextSubStep,
    prePageFirst,
    t,
    dataAccount,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const headerRef = useRef();
  const [scrollHeight, setScrollHeight] = useState("100vh");

  const scrollRef = useRef(null);

  const preSubStep = usePrevious(subStep);

  useEffect(() => {
    if (subStep > 5 && subStep < 8) {
      history.push("/open-account/register-service");
    } else if (subStep > 8) history.push("/open-account/sign-contract");
    else history.push("/open-account/check-info");
  }, []);

  useEffect(() => {
    const calculateScrollHeight = () => {
      const headerHeight =
        headerRef.current?.getBoundingClientRect().height || 0;
      const extraHeight = window.innerWidth < 768 ? 92 : 0;
      setScrollHeight(`calc(100vh - ${headerHeight + 120 + extraHeight}px)`);
    };

    // Tính toán chiều cao ban đầu và khi resize
    calculateScrollHeight();
    window.addEventListener("resize", calculateScrollHeight);

    // Cuộn về đầu khi thay đổi subStep
    if (subStep && !_.isEqual(subStep, preSubStep) && scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }

    return () => {
      window.removeEventListener("resize", calculateScrollHeight);
    };
  }, [subStep]);

  useEffect(() => {
    const fetchAccountData = async () => {
      if (!dataAccount?.C_ACCOUNT && subStep === 2 && subStep > preSubStep) {
        await dispatch(getListAccSuccess(null));
        handleGetListAcc();
      }
      if (dataAccount?.C_ACCOUNT && subStep === 1) {
        let _dataAcc = Object.assign({}, dataAccount);

        _dataAcc.C_ACCOUNT = "";

        dispatch(setDataAccount(_dataAcc));
      }
    };

    fetchAccountData();
  }, [dataAccount, subStep]);

  const handleGetListAcc = () => {
    const params = {
      user: "back",
      cmd: "ListAccountForOpen",
      param: {},
      sid: dataAccount.S_ID,
    };

    dispatch(getListAccount(params));
  };

  return (
    <>
      <div
        className="md:col-span-2 grid gap-2 auto-rows-max box-top_margin"
        ref={headerRef}
      >
        {/* <span className="text-sm font-normal mobile-only text-skin-body">
          {t("titleStep-1")} <span className="text-skin-body"> {subStep}</span>
          /5
        </span> */}

        <div className=" w-100 grid grid-cols-5 gap-1">
          <div className="col-span-5 style-bg-header">
            <div className="d-flex align-items-center rounded-full border-skin-gray text-xs pl-2 pr-2">
              {subStep} / 5
            </div>
            <span className="text-base font-semibold text-skin-title ml-2">
              {subStep === 1
                ? t("infor-acc")
                : subStep === 2
                ? t("select-acc")
                : subStep === 3
                ? t("res-docs")
                : subStep === 4
                ? t("add-infor")
                : subStep === 5
                ? t("auth-face")
                : ""}
            </span>
          </div>

          {subStep === 1 && (
            <span
              className="col-span-5 text-xs text-skin-body"
              style={{
                marginTop: "12px",
              }}
            >
              * {t("detail-title-1")}{" "}
            </span>
          )}
          {subStep === 2 && (
            <span
              className="col-span-5 text-xs text-skin-body"
              style={{
                marginTop: "12px",
              }}
            >
              * {t("detail-title-2")}
            </span>
          )}
        </div>
      </div>
      <PerfectScrollbar
        className="md:col-span-2 grid gap-2 auto-rows-max"
        style={{ height: scrollHeight }}
        containerRef={(el) => {
          scrollRef.current = el;
        }}
      >
        {subStep === 1 && (
          <FormCheckAccount handleNextSubStep={handleNextSubStep} />
        )}
        {subStep === 2 && (
          <FormSelectAccount
            handleNextSubStep={handleNextSubStep}
            handlePreSubStep={handlePreSubStep}
            prePageFirst={prePageFirst}
          />
        )}
        {subStep === 3 && (
          <FormAuthDocs
            handleNextSubStep={handleNextSubStep}
            handlePreSubStep={handlePreSubStep}
            handleGetOrcData={handleGetOrcData}
            prePageFirst={prePageFirst}
          />
        )}
        {subStep === 4 && (
          <FormAddInfor
            handleNextSubStep={handleNextSubStep}
            handlePreSubStep={handlePreSubStep}
            prePageFirst={prePageFirst}
            orcData={orcData}
          />
        )}
        {subStep === 5 && (
          <FormAuthFace
            handleNextSubStep={handleNextSubStep}
            handlePreSubStep={handlePreSubStep}
            prePageFirst={prePageFirst}
          />
        )}
      </PerfectScrollbar>
    </>
  );
}

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  return {
    dataAccount: getDataAccount(state),
  };
};

export default connect(mapStateToProps)(
  translate("translations")(PageBaseInfor)
);
