export default {
  "session-expired": "Hết hiệu lực thời gian đăng ký. Vui lòng thực hiện lại!",
  notification: "Thông báo",
  "processing-error": "Có lỗi trong quá trình xử lý. Vui lòng thử lại!",
  "camera-unavailable": "Máy ảnh không khả dụng trên thiết bị này",
  "browser-unsafe":
    "Trình duyệt của bạn không an toàn. Vui lòng sử dụng trình duyệt khác hoặc cập nhật trình duyệt hiện tại",
  "https-required":
    "Trang web này phải được truy cập qua HTTPS để sử dụng máy ảnh.",
};
