import React, { Suspense, useEffect } from "react";
import * as router from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { toast as notify } from "react-toastify";

import OpenAccount from "../account";

import Loading from "../../components/loading";

import * as _ from "lodash";
import { makeGetToast } from "../../lib/selector";

import { usePrevious } from "../../lib/useHook";
import { makeGetDataAccount } from "../../lib/selector";
import { ToastContainer } from "react-toastify";
import ToastSuccess from "../../assets/img/toast/success.png";
import ToastWarning from "../../assets/img/toast/warrning.png";
import ToastError from "../../assets/img/toast/error.png";
import ToastInfo from "../../assets/img/toast/info.png";
import RenderContentToast from "../../util/ToastContent";
const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

const PrivateRoute = ({ component: Component, ...rest }) => {
  // console.log(rest)
  return (
    <router.Route
      {...rest}
      render={(props) => {
        return <Component {...props} store={rest.dispatch.store} />;
      }}
    />
  );
};

function DefaultLayout(props) {
  const dispatch = useDispatch();

  const { toast } = props;

  const prevToast = usePrevious(toast);

  useEffect(() => {
    if (toast && toast.msg && !_.isEqual(toast, prevToast)) {
      if (toast.type) {
        notify[toast.type](<RenderContentToast toast={toast} />, {
          icon: ({ type }) => (
            <img
              src={
                type === "success"
                  ? ToastSuccess
                  : type === "error"
                  ? ToastError
                  : type === "warning"
                  ? ToastWarning
                  : ToastInfo
              }
            />
          ),
        });
      } else
        notify.info(toast.msg?.message || toast.msg, {
          icon: () => <img src={ToastInfo} />,
        });
      dispatch({ type: "CLEAR_TOAST" });
    }
  }, [toast]);

  return (
    <div
      className="app overflow-hidden h-screen bg-skin-body text-skin-base w-screen"
      style={{
        padding: "0 1.5rem 0",
      }}
    >
      <DefaultHeader />
      <div className={"app-body w-100 "}>
        <main className="w-100 ">
          <Container fluid>
            <Suspense fallback={<Loading />}>
              <router.Switch>
                <PrivateRoute
                  path="/open-account/:typeId"
                  component={OpenAccount}
                  dispatch={props}
                />
                <router.Redirect
                  from="/open-account"
                  to="/open-account/check-info"
                />
              </router.Switch>
            </Suspense>
          </Container>
        </main>
      </div>
      <DefaultFooter />
      <ToastContainer autoClose={2000} pauseOnHover={false} pauseOnFocusLoss />
    </div>
  );
}

const makeMapStateToProps = () => {
  const getToast = makeGetToast();
  const getDataAccount = makeGetDataAccount();

  const mapStateToProps = (state) => {
    return {
      toast: getToast(state),
      dataAccount: getDataAccount(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(DefaultLayout);
