/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import _ from "lodash";
import PerfectScrollBar from "react-perfect-scrollbar";

function usePrevious(value) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useKey(key, cb) {
  const callback = React.useRef(cb);

  React.useEffect(() => {
    callback.current = cb;
  });

  React.useEffect(() => {
    function handle(event) {
      if (event.code === key) {
        callback.current(event);
      } else if (key === "enter" && event.keyCode === 13) {
        callback.current(event);
      }
    }

    document.addEventListener("keydown", handle);
    return () => document.removeEventListener("keydown", handle);
  }, [key]);
}

const RenderSearchSelectBank = (props) => {
  const showRef = React.useRef(null);
  const inputRef = React.useRef(null);

  const [txtSearch, setTxtSearch] = React.useState("");
  const [showSelect, setShowSelect] = React.useState(false);
  const [showAva, setShowAva] = React.useState(false);
  const [filteredOpts, setFilteredOpts] = React.useState([]);
  const [indexActive, setIndexActive] = React.useState(-1);

  const {
    placeholderText,
    input,
    disabled,
    opts,
    required = false,
    className,
    meta: { touched, error },
  } = props;

  const preValue = usePrevious(input?.value);
  const preTxtSearch = usePrevious(txtSearch);

  const preIndexActive = usePrevious(indexActive);

  React.useEffect(() => {
    if (indexActive > -1 && !_.isEqual(indexActive, preIndexActive)) {
      let _rootRow = document.getElementById("active-select");
      if (_rootRow) {
        _rootRow.scrollIntoViewIfNeeded(false);
      }
    }
  }, [indexActive]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showRef &&
        showRef.current &&
        !showRef.current?.contains(event.target)
      ) {
        setShowSelect(false);
        handleSetInput();
      }
    };

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  React.useEffect(() => {
    if (input?.value && !_.isEqual(input.value, preValue)) {
      setIndexActive(-1);

      const selectedOpt = opts.find(
        (o) => o.C_BANK_CODE === input.value || o.C_BANK_NAME === input.value
      );
      if (selectedOpt) {
        setTxtSearch(selectedOpt.C_BANK_NAME);
        input.onChange(selectedOpt.C_BANK_CODE);

        setShowAva(true);
      } else {
        setShowAva(false);
      }
    } else if (!input?.value) {
      setShowAva(false);
      setTxtSearch("");
    }
  }, [input?.value]);

  React.useEffect(() => {
    if (txtSearch && !_.isEqual(txtSearch, preTxtSearch)) {
      setIndexActive(-1);

      const selectedOpt = opts.find(
        (o) => o.C_BANK_NAME.toLowerCase() === txtSearch.toLowerCase()
      );
      if (selectedOpt) {
        input.onChange(selectedOpt.C_BANK_CODE);
        setTxtSearch(selectedOpt.C_BANK_NAME);
      } else {
        setShowAva(false);
      }
    } else {
      setShowAva(false);
      input.onChange("");
    }
  }, [txtSearch]);

  React.useEffect(() => {
    if (opts) {
      let filtered = opts.filter((opt) =>
        opt?.C_BANK_CODE.toLowerCase().includes(txtSearch.toLowerCase())
      );

      if (filtered.length === 0) {
        filtered = opts.filter((opt) =>
          opt?.C_BANK_NAME.toLowerCase().includes(txtSearch.toLowerCase())
        );
      }

      setFilteredOpts(filtered.length > 0 ? filtered : []);
    }
  }, [txtSearch, opts]);

  function handleSetInput() {
    const find = _.find(opts, (o) => o.C_BANK_NAME === txtSearch);
    if (find) {
      input.onChange(find.C_BANK_CODE);
      setTxtSearch(find.C_BANK_NAME);
      setShowAva(true);
    } else {
      input.onChange("");
      setTxtSearch("");
      setShowAva(false);
    }
  }

  function handleSelect(val) {
    setShowSelect(false);
    setTxtSearch(val.C_BANK_NAME);
    input.onChange(val.C_BANK_CODE);
  }

  function onKeyDown(e) {
    if (!filteredOpts.length) {
      setShowSelect(false);
      return;
    }

    if (e.key === "Tab" || e.keyCode === 9) {
      input.onChange(filteredOpts && filteredOpts[0]?.C_BANK_CODE);
      setTxtSearch(filteredOpts && filteredOpts[0]?.C_BANK_NAME);
      setShowSelect(false);
    }

    if (e.key === "ArrowDown" || e.keyCode === 40) {
      e.preventDefault();
      if (indexActive < filteredOpts.length - 1) {
        setIndexActive(indexActive + 1);
      } else setIndexActive(-1);
    }

    if (e.key === "ArrowUp" || e.keyCode === 38) {
      e.preventDefault();
      if (indexActive > 0) setIndexActive(indexActive - 1);
      if (indexActive === 0) setIndexActive(-1);
      if (indexActive < 0) setIndexActive(filteredOpts.length - 1);
    }

    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      if (indexActive < 0 || indexActive > filteredOpts.length) return;
      // setSelected(true)
      const _record = filteredOpts[indexActive];
      setTxtSearch(_record.C_BANK_NAME);
      input.onChange(_record.C_BANK_CODE);
      setShowSelect(false);
      if (inputRef.current && inputRef) inputRef.current?.blur();
    }
  }

  return (
    <>
      <div
        className={
          `flex flex-row items-center rounded-3xl w-100 relative  ` +
          `${touched && error ? "border border-skin-sell" : "border-0"} ` +
          (showSelect ? "border border-skin-white " : " ") +
          (className || "")
        }
        onClick={() => setShowSelect(!showSelect)}
        ref={showRef}
      >
        <div
          className={`img-back bg-center bg-cover ` + (showAva ? "" : "hidden")}
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + `/images/bank/${input?.value}.png`
            })`,
            backgroundPosition: "center",
            backgroundSize: "cover",

            width: "24px",
            height: "24px",
            marginLeft: "8px",
          }}
        ></div>
        <div
          className="relative"
          style={{
            width: "calc(100% - 45px)",
            paddingLeft: "4px",
          }}
        >
          <input
            {...input}
            type="text"
            className={`input-search w-100 border-none bg-input-text rounded-3xl text-base font-normal text-skin-white `}
            value={txtSearch}
            placeholder={placeholderText || ""}
            onChange={(e) => {
              setTxtSearch(e.target.value);
              setShowSelect(true);
            }}
            onClick={() => setShowSelect(true)}
            onFocus={(e) => e.target.select()}
            onKeyDown={onKeyDown}
            ref={inputRef}
          />
        </div>
        <ul
          className={
            "scroll-cus absolute border border-skin-weak bg-skin-primary rounded-2xl select-cus " +
            (showSelect ? "" : "hidden")
          }
          style={{
            zIndex: "10",
            width: "100%",
            maxHeight: "244px",
            left: "0",
            top: "calc(100% + 2px)",
            padding: "16px",
            marginTop: "4px",
          }}
        >
          <PerfectScrollBar
            style={{
              maxHeight: "220px",
            }}
          >
            {!required && (
              <li className="text-sm font-medium text-skin-subdued">
                Kết quả hàng đầu
              </li>
            )}
            {filteredOpts &&
              !!filteredOpts.length &&
              filteredOpts.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleSelect(item)}
                  className={
                    "relative cursor-pointer text-sm flex items-center rounded-3xl bank-li " +
                    (indexActive === index
                      ? "bg-skin-navActive bank-span-active "
                      : "bg-skin-inversewhite ")
                  }
                  style={{
                    height: "36px",
                    padding: "0px 12px",
                    margin: "8px 0px",
                  }}
                  id={indexActive === index ? "active-select" : ""}
                >
                  <div
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL +
                        `/images/bank/${item.C_BANK_CODE}.png`
                      })`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      width: "24px",
                      height: "24px",
                      minWidth: "24px",
                    }}
                  ></div>
                  <span
                    className={
                      `truncate text-skin-subdued bank-span ` +
                      (indexActive === index ? " bank-span-active " : " ")
                    }
                    style={{
                      marginLeft: "8px",
                    }}
                  >
                    {item.C_BANK_NAME}
                  </span>
                </li>
              ))}
          </PerfectScrollBar>
        </ul>
      </div>
      {touched && error && (
        <small className="text-left text-sm text-skin-red">{error}</small>
      )}
    </>
  );
};

export default RenderSearchSelectBank;
