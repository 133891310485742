import React, { memo } from "react";

const RenderFieldCheckbox = (props) => {
  const { input, checked, disabled, labelName, onChange, name, itemID } = props;
  return (
    <label className="container-checkbox !m-auto">
      {labelName}
      <input
        {...input}
        type="checkbox"
        name={name || input?.name}
        id={name || input?.name}
        disabled={disabled}
        checked={checked || input?.value}
        onChange={onChange || input?.onChange}
        itemID={itemID}
      />
      <span className="check_mark" />
    </label>
  );
};

export default memo(RenderFieldCheckbox);
