import { FunctionComponent, useState } from "react";
import { ReactComponent as IcEye } from "../../assets/img/icon/eye.svg";
import { ReactComponent as IcEyeSlash } from "../../assets/img/icon/eye-slash.svg";
import { ReactComponent as IcTick } from "../../assets/img/icon/tick.svg";
import { ReactComponent as IcNoTick } from "../../assets/img/icon/no-tick.svg";
import * as _ from "lodash";

const listTick = [
  "valid.passLength",
  "valid.passLetter",
  "valid.passNumber",
  "valid.passCharacter",
];

const RenderLoginInput = (props) => {
  const {
    input,
    type,
    disabled,
    placeholder,
    meta,
    isTick,
    className,
    onKeyDown,
    maxLength,
  } = props;
  const { touched, error, warning, active } = meta;
  const [typeInput, setTypeInput] = useState(
    type === "password" ? "password" : type === "number" ? "number" : "text"
  );

  return (
    <div className="d-flex flex-column relative">
      <div className="w-100 rounded-full flex ">
        <input
          {...input}
          type={typeInput}
          className={
            "w-100 rounded-full text-skin-white text-input-trading text-sm bg-skin-input input-login disabled:text-skin-subdued " +
            (touched && error ? "border !border-skin-valid " : "border-none ") +
            className
          }
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
          style={{
            height: "36px",
            padding: "8px 16px",
          }}
          onKeyDown={onKeyDown ? onKeyDown : () => {}}
          maxLength={maxLength}
        />
      </div>
      {type === "password" && (
        <span
          className={"absolute " + (touched && error ? "icon-valid" : "")}
          onClick={() =>
            setTypeInput(typeInput === "text" ? "password" : "text")
          }
          style={{
            top: "6px",
            right: "12px",
          }}
        >
          {typeInput === "text" ? <IcEye /> : <IcEyeSlash />}
        </span>
      )}
      {touched && error && (
        <div className="text-sm text-skin-red ">{error}</div>
      )}
      {isTick && active && (
        <ul className="absolute top-0 -right-[220px] p-3 bg-skin-card border border-skin-strong rounded-xl grid gap-3 z-50">
          <li className="text-sm">Mật khẩu mới bao gồm</li>
          {listTick &&
            listTick.map((item, index) => {
              return (
                <li key={index} className="flex text-xs items-center">
                  {_.some(warning, (o) => o === item) ? (
                    <>
                      <IcNoTick className="mr-1" />
                      <span className="text-skin-red">{item + ""}</span>
                    </>
                  ) : (
                    <>
                      <IcTick className="mr-1" />
                      <span className="text-skin-active">{item + ""}</span>
                    </>
                  )}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default RenderLoginInput;
