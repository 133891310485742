import React, { FunctionComponent } from "react";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";

const _regexp = /^[0-9\b]+$/;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const RenderAccount = (props) => {
  const {
    input,
    meta: { touched, error },
    disabled,
  } = props;

  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const inputsRef = useRef([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const preInputValues = usePrevious(inputValues);
  const preValue = usePrevious(input.value);

  useEffect(() => {
    if (input.value && !_.isEqual(input.value, preValue)) {
      const newValues = input.value.split("").slice(0, 6);
      setInputValues((prevValues) =>
        newValues.map((val, index) => val || prevValues[index])
      );
    }
  }, [input.value]);

  useEffect(() => {
    if (inputValues && !_.isEqual(inputValues, preInputValues))
      setIsDeleting(false);
    input?.onChange(inputValues.join(""));
  }, [inputValues]);

  const handleInputChange = (index, e) => {
    const val = e.target.value;

    if (isNaN(Number(val))) {
      e.target.value = "";
      return;
    }

    const newInputValues = [...inputValues];
    newInputValues[index] = val;
    setInputValues(newInputValues);

    if (val !== "") {
      const nextIndex = index + 1;
      if (inputsRef.current[nextIndex]) {
        inputsRef.current[nextIndex].focus();
      }
    }
  };

  const handleKeyUp = (index, e) => {
    const key = e.key.toLowerCase();
    const currentValue = inputValues[index];

    if (key === "delete" || key === "backspace") {
      e.preventDefault();

      if (isDeleting && currentValue === "") {
        const prevIndex = index - 1;
        if (prevIndex >= 0 && inputValues[prevIndex] !== "") {
          if (inputsRef.current[prevIndex]) {
            inputsRef.current[prevIndex].focus();
          }
        }
      } else {
        const newInputValues = [...inputValues];
        newInputValues[index] = "";
        setInputValues(newInputValues);
        setIsDeleting(true);
      }
    } else {
      setIsDeleting(false);
    }
  };

  return (
    <div id="inputs" className="inputs grid-list-input">
      {[...Array(6)].map((_, index) => (
        <div
          key={index}
          className="style-input-w flex items-center justify-center bg-skin-sur-primary rounded-xl border border-skin-weak"
        >
          <input
            disabled={disabled}
            value={inputValues[index]}
            className={`div-input-ab border-none bg-skin-sur-primary font-semibold text-skin-title text-center`}
            type="text"
            inputMode="numeric"
            maxLength={1}
            id={`inputAcc`}
            ref={(el) => {
              el && (inputsRef.current[index] = el);
            }}
            autoComplete="off"
            onChange={(e) => handleInputChange(index, e)}
            onKeyUp={(e) => handleKeyUp(index, e)}
          />
        </div>
      ))}
    </div>
  );
};

export default RenderAccount;
