export default {
  "session-expired": "Session has expired. Please try again!",
  notification: "Notification",
  "processing-error":
    "There was an error processing the request. Please try again!",
  "camera-unavailable": "The camera is not available on this device",
  "browser-unsafe":
    "Your browser is unsafe. Please use a different browser or update your current browser",
  "https-required":
    "This website must be accessed via HTTPS to use the camera.",
};
