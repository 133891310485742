import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";

import PerfectScrollbar from "react-perfect-scrollbar";

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function RenderNormalSearch(props) {
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [indexActive, setIndexActive] = useState(-1);

  const { opts, className, placeholderText, disabled } = props;
  const {
    input,
    meta: { touched, error },
  } = props;

  const preValue = usePrevious(input.value);
  const preIndexActive = usePrevious(indexActive);

  React.useEffect(() => {
    if (indexActive > -1 && !_.isEqual(indexActive, preIndexActive)) {
      let _rootRow = document.getElementById("active-select");
      if (_rootRow) {
        _rootRow.scrollIntoViewIfNeeded(false);
      }
    }
  }, [indexActive]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    // See note below
    return () => window.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    if (!_.isEqual(input.value, preValue)) {
      if (input.value) {
        const _filter = _.find(opts, (o) => o.value === input.value);
        if (!_filter) return;
        setValue(_filter?.label);
      } else setValue("");
    }
  }, [input.value]);

  const handleClickOutside = (event) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      setShowSuggestions(false);
      setFilteredSuggestions([]);

      if (input.value && value) {
        const _filter = _.find(opts, (o) => o.value === input.value);
        if (!_filter) {
          input.onChange(null);
          setValue("");
        } else {
          input.onChange(_filter?.value);
          setValue(_filter?.label);
        }
      } else {
        input.onChange(null);
        setValue("");
      }
    }
  };

  function onKeyDown(e) {
    if (!filteredSuggestions.length) {
      setShowSuggestions(false);
      return;
    }

    if (e.key === "Tab" || e.keyCode === 9) {
      input.onChange(filteredSuggestions[0].value);
      setShowSuggestions(false);
    }

    if (e.key === "ArrowDown" || e.keyCode === 40) {
      e.preventDefault();
      if (indexActive < filteredSuggestions.length - 1) {
        setIndexActive(indexActive + 1);
      } else setIndexActive(-1);
    }

    if (e.key === "ArrowUp" || e.keyCode === 38) {
      e.preventDefault();
      if (indexActive > 0) setIndexActive(indexActive - 1);
      if (indexActive === 0) setIndexActive(-1);
      if (indexActive < 0) setIndexActive(filteredSuggestions.length - 1);
    }

    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      if (indexActive < 0 || indexActive > filteredSuggestions.length) return;
      const _record = filteredSuggestions[indexActive];
      input.onChange(_record.value);
      setValue(_record.label);
      setShowSuggestions(false);

      if (inputRef.current && inputRef) inputRef.current?.blur();
    }
  }

  function _handleFocus(e) {
    const { value } = e.target;
    const _val = value ? value.trim() : "";
    const escapedValue = escapeRegexCharacters(_val);
    if (escapedValue === "") {
      setShowSuggestions(true);
      setFilteredSuggestions(opts);

      return;
    }

    setShowSuggestions(true);

    const filteredSuggestions = _.filter(opts, (o) =>
      o.label.toLowerCase().includes(escapedValue.toLowerCase())
    );
    setFilteredSuggestions(filteredSuggestions);
  }

  function onChange(e) {
    const { value } = e.target;
    setValue(value);
    const _val = value ? value.trim() : "";
    const escapedValue = escapeRegexCharacters(_val);
    if (escapedValue === "") {
      setShowSuggestions(true);
      setFilteredSuggestions(opts);

      return;
    }

    setShowSuggestions(true);

    const filteredSuggestions = _.filter(opts, (o) =>
      o.label.toLowerCase().includes(escapedValue.toLowerCase())
    );
    setFilteredSuggestions(filteredSuggestions);
  }

  function _handleClick(params) {
    console.log(params);
    input.onChange(params.value);
    setValue(params.label);
    setShowSuggestions(false);
  }

  console.log(input.value);

  return (
    <div className="relative" ref={wrapperRef}>
      <input
        type="text"
        className={
          "w-100 rounded-full text-skin-white text-input-trading text-base bg-skin-input input-login disabled:text-skin-subdued " +
          (touched && error ? "border !border-skin-valid " : "border-none ") +
          className
        }
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete="off"
        autoCorrect="off"
        disabled={disabled}
        placeholder={placeholderText}
        onFocus={_handleFocus}
        ref={inputRef}
        style={{
          height: "36px",
        }}
      />
      {showSuggestions && (
        <div
          className={
            "scroll-cus absolute left-0 top-[calc(100%+2px)] border border-skin-weak bg-skin-primary rounded-2xl select-cus mt-1 "
          }
          style={{
            minWidth: "100%",
            zIndex: "9999",
            padding: "16px",
          }}
        >
          <PerfectScrollbar style={{ maxHeight: "200px", height: "auto" }}>
            <ul>
              {filteredSuggestions &&
                !!filteredSuggestions.length &&
                filteredSuggestions.map((item, index) => (
                  <li
                    className={
                      "relative cursor-pointer text-sm flex items-center rounded-3xl bank-li " +
                      (indexActive === index
                        ? "bg-skin-navActive bank-span-active "
                        : "bg-skin-inversewhite ")
                    }
                    style={{
                      height: "36px",
                      padding: "0px 12px",
                      margin: "8px 0px",
                    }}
                    id={indexActive === index ? "active-select" : ""}
                    key={item.value}
                    onClick={() => _handleClick(item)}
                  >
                    <span
                      className={
                        `truncate text-skin-subdued bank-span ` +
                        (indexActive === index ? " bank-span-active " : " ")
                      }
                    >
                      {item.label}
                    </span>
                  </li>
                ))}
            </ul>
          </PerfectScrollbar>
        </div>
      )}
      {touched && error && (
        <small
          className="text-left text-xs 2xl:text-sm"
          style={{
            color: "var(--color-bg-sell)",
            margin: "0 0 10px",
          }}
        >
          {error}
        </small>
      )}
    </div>
  );
}

export default RenderNormalSearch;
