import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { makeGetDataAccount } from "../../../lib/selector";
import { setSaveTimeSS, setToast } from "../../client/actions";

import _ from "lodash";
import { translate } from "react-i18next";
import RenderFieldCheckbox from "../../../components/input/renderCheckbox";
import RenderLoginInput from "../../../components/input/renderInputLogin";
import { handleApiErrors } from "../../../lib/api-errors";

import { replaceAll } from "../../../util";
import {
  required,
  validateEmail,
  validatePhone,
} from "../../../util/validation";
import { setDataAccount } from "../../client/actions";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function PageCheckAccount(props) {
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const {
    handleSubmit,
    formReferralCode,
    dataAccount,
    submitting,
    handleNextSubStep,
    t,
  } = props;
  const [loading, setLoading] = useState(false);
  const [validAgree, setValidAgree] = useState(true);
  const [validCon, setValidCon] = useState(false);
  const [nameSale, setNameSale] = useState();

  const preFormReferralCode = usePrevious(formReferralCode);

  useEffect(() => {
    innitForm();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (formReferralCode && !_.isEqual(formReferralCode, preFormReferralCode)) {
      if (formReferralCode.length !== 4) {
        setValidCon(true);
        dispatch(change("formResStep11", "formNamePre", ""));
      } else {
        debouncedSearch(formReferralCode);
        setValidCon(false);
      }
    } else {
      setValidCon(false);
    }
  }, [formReferralCode]);

  useEffect(() => {
    if (!nameSale) {
      dispatch(change("formResStep11", "formNamePre", ""));
    } else
      dispatch(change("formResStep11", "formNamePre", nameSale[0].SALE_NAME));
  }, [nameSale]);

  const innitForm = () => {
    dispatch(change("formResStep11", "formMobile", dataAccount?.C_MOBILE));
    dispatch(change("formResStep11", "formEmail", dataAccount?.C_EMAIL));
    dispatch(
      change("formResStep11", "formReferralCode", dataAccount?.C_SALE_ID)
    );
    dispatch(change("formResStep11", "formNamePre", dataAccount?.C_SALE_NAME));
  };

  function submit(values) {
    const { formEmail, formMobile } = values;
    const startTime = Date.now();
    dispatch(setSaveTimeSS(startTime));

    // setLoading(true);

    const param = {
      user: "back",
      cmd: "CHECK_OPENACC",
      param: {
        C_MOBILE: replaceAll(formMobile, " ", ""),
        C_EMAIL: formEmail,
      },
    };
    checkAccountRequestApi(param, values);
  }

  const getSaleId = (values) => {
    setLoading(true);

    const params = {
      user: "back",
      cmd: "GET_SALEID",
      param: {
        C_SALE_ID: values,
      },
      sid: "",
    };

    const request = fetch(`${apiUrl}/getSaleId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiSaleId(request);
  };

  const handleApiSaleId = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          setNameSale(json.data);
          setLoading(false);
          setValidCon(false);
        } else {
          // _handleToast(json.sRs, 'error');
          setValidCon(true);
          setLoading(false);

          setNameSale("");
        }
      })
      .catch((error) => {
        setLoading(false);
        _handleToast(t("processing-error"), "error");
        // throw error;
      });
  };

  function checkAccountRequestApi(data, values) {
    const url = `${apiUrl}/checkEmailPhone`;
    const request = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(data),
      signal: abortController.signal,
    });

    request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          let _dataAcc = Object.assign({}, dataAccount);

          _dataAcc.S_ID = json?.sid || "";
          _dataAcc.C_MOBILE = replaceAll(values.formMobile, " ", "");
          _dataAcc.C_EMAIL = values.formEmail;
          _dataAcc.C_SALE_ID = values.formReferralCode;
          _dataAcc.C_SALE_NAME = values.formNamePre;

          dispatch(setDataAccount(_dataAcc));
          handleNextSubStep();
        } else {
          // đã có tài khoản
          _handleToast(json.sRs, "error");
        }
      })
      .catch((error) => {
        _handleToast(t("processing-error"), "error");
        // throw error;
      });
  }

  const _handleToast = (msg, type = "info") => {
    const toastMsg = {
      id: Math.random(),
      type,
      title: t("notification"),
      msg,
    };
    dispatch(setToast(toastMsg));
  };

  const debouncedSearch = useRef(_.debounce(getSaleId, 200)).current;

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="grid grid grid-cols-2 gap-r-6 form-group"
    >
      <div className="col-span-2">
        <div
          className="text-xs font-medium"
          style={{
            marginBottom: "8px",
          }}
        >
          {t("number-phone")} <small className="text-danger">*</small>
        </div>
        <Field
          name="formMobile"
          autoComplete="off"
          placeholder={t("number-phone-input")}
          component={RenderLoginInput}
          validate={[required, validatePhone]}
        />
      </div>
      <div className="col-span-2">
        <div
          className="text-xs font-medium"
          style={{
            marginBottom: "8px",
          }}
        >
          {t("email")} <small className="text-danger">*</small>
        </div>
        <Field
          name="formEmail"
          autoComplete="off"
          placeholder={"VD: cskh@upstock.com.vn"}
          component={RenderLoginInput}
          validate={[required, validateEmail]}
        />
      </div>
      <div className="grid-res-1">
        <div
          className="text-xs font-medium"
          style={{
            marginBottom: "8px",
          }}
        >
          {t("sale-id")}{" "}
        </div>
        <Field
          name="formReferralCode"
          autoComplete="off"
          placeholder={t("sale-id-t")}
          component={RenderLoginInput}
          maxLength="4"
        />
        {validCon ? (
          <small className="text-sm font-normal text-red-600">
            * {t("sale-invalid")}
          </small>
        ) : (
          <span className="text-sm font-normal text-skin-subdued">
            {t("optional")}{" "}
          </span>
        )}
      </div>
      <div className="grid-res-1 ">
        <div
          className="text-xs font-medium text-skin-disable"
          style={{
            marginBottom: "8px",
          }}
        >
          {t("sale-name")}{" "}
        </div>
        <Field
          name="formNamePre"
          autoComplete="off"
          placeholder={t("sale-name")}
          component={RenderLoginInput}
          disabled
        />
      </div>
      <div className="!text-skin-muted col-span-2 d-flex flex-column">
        <div className="d-flex flex-row">
          <div className="d-flex align-items-baseline mt-1">
            <Field
              name="isAgree"
              component={RenderFieldCheckbox}
              checked={validAgree}
              onChange={() => setValidAgree(!validAgree)}
            />
          </div>

          <span className="text-skin-subdued text-sm">{t("detailStep-1")}</span>
        </div>

        {!validAgree && (
          <span className="text-sm font-normal text-skin-red">
            {t("detailStep-2")}
          </span>
        )}
      </div>
      <button
        type="submit"
        disabled={submitting || !validAgree || validCon}
        className="!max-w-52 success col-span-2 btn-submit"
        style={{
          width: "120px",
          height: "40px",
        }}
      >
        {t("continue-step")}
      </button>
      {loading && <div className="loader-full"></div>}
    </form>
  );
}

PageCheckAccount = reduxForm({
  form: "formResStep11",
  enableReinitialize: true,
})(PageCheckAccount);

const selector = formValueSelector("formResStep11");

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  const { formMobile, formEmail, formReferralCode, formNamePre } = selector(
    state,
    "formMobile",
    "formEmail",
    "formReferralCode",
    "formNamePre"
  );

  return {
    dataAccount: getDataAccount(state),

    formMobile,
    formEmail,
    formReferralCode,
    formNamePre,
  };
};

export default connect(mapStateToProps)(
  translate("translations")(PageCheckAccount)
);
