import {
  CHECK_ACC_REQUESTING,
  CHECK_ACC_REQUEST_ERROR,
  CHECK_ACC_REQUEST_SUCCESS,
  PUSH_LOG_REQUESTING,
  PUSH_LOG_REQUEST_ERROR,
  PUSH_LOG_REQUEST_SUCCESS,
  LIST_ACC_REQUESTING,
  LIST_ACC_REQUEST_ERROR,
  LIST_ACC_REQUEST_SUCCESS,
  DATA_ACCOUNT_SET,
  SAVE_LIST_ACC_HOLD,
  SET_STEP_RES,
  SAVE_TIME_SS,
} from "./constants";

export const setToast = function setToast(resData) {
  return {
    type: "SHOW_TOAST",
    resData,
  };
};

export function setSetting(data) {
  return {
    type: "SETTING_SET",
    data,
  };
}

export const setDataAccount = function setDataAccount(resData) {
  return {
    type: DATA_ACCOUNT_SET,
    resData,
  };
};

export const setSaveListAccHold = function setSaveListAccHold(resData) {
  return {
    type: SAVE_LIST_ACC_HOLD,
    resData,
  };
};

export const setSaveTimeSS = function setSaveTimeSS(resData) {
  return {
    type: SAVE_TIME_SS,
    resData,
  };
};

export const setStepRes = function setStepRes(resData) {
  return {
    type: SET_STEP_RES,
    resData,
  };
};

export const checkAccountRequest = function checkAccountRequest(data) {
  return {
    type: CHECK_ACC_REQUESTING,
    data,
  };
};

export const checkAccountRequestSuccess = function checkAccountRequestSuccess(
  resData
) {
  return {
    type: CHECK_ACC_REQUEST_SUCCESS,
    resData,
  };
};

export const checkAccountRequestError = function checkAccountRequestError(
  error
) {
  return {
    type: CHECK_ACC_REQUEST_ERROR,
    error,
  };
};

export const pushLog = function pushLog(data) {
  return {
    type: PUSH_LOG_REQUESTING,
    data,
  };
};

export const pushLogSuccess = function pushLogSuccess(resData) {
  return {
    type: PUSH_LOG_REQUEST_SUCCESS,
    resData,
  };
};

export const pushLogError = function pushLogError(error) {
  return {
    type: PUSH_LOG_REQUEST_ERROR,
    error,
  };
};

export const getListAccount = function getListAccount(data) {
  return {
    type: LIST_ACC_REQUESTING,
    data,
  };
};

export const getListAccSuccess = function getListAccSuccess(resData) {
  return {
    type: LIST_ACC_REQUEST_SUCCESS,
    resData,
  };
};

export const getListAccError = function getListAccError(error) {
  return {
    type: LIST_ACC_REQUEST_ERROR,
    error,
  };
};
