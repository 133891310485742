import React from "react";

const RenderRadio = (props) => {
  const { input, disabled, className, labelName, id } = props;

  return (
    <label className="container-radio">
      <input {...input} type="radio" id={id} disabled={disabled} />
      <label
        htmlFor={id}
        className={`lable-i label-radio font-medium ${
          disabled ? "text-skin-disable" : "text-skin-title"
        }`}
      >
        {labelName}
      </label>
      <span className="checkmark"></span>
    </label>
  );
};

export default RenderRadio;
